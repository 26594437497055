import styled, { device } from "@styled";

export const BoxSectionWrapper = styled.section`
  padding: 85px 0;
`;

export const BoxSectionInner = styled.div``;

export const BoxSectionWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  ${device.medium} {
    grid-template-columns: repeat(2, 1fr);
  }
  ${device.xlarge} {
    grid-template-columns: repeat(3, 1fr);
  }
`;
export const BoxSectionWrapInner = styled.div`
  padding: 25px;
  border: 1px solid rgb(255 255 255 / 55%);
  border-radius: 6px;
  overflow: hidden;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  .copyButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    padding: 0;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    &:hover {
      background: #fff;
      box-shadow: 0 0 10px 0 #9e2600;
      img {
        filter: invert(0);
      }
      span {
        color: #000;
      }
    }
    img {
      max-width: 16px;
      filter: invert(1);
    }
    span {
      color: #000;
      position: absolute;
      right: calc(100% + 5px);
      background: #ffffff8a;
      padding: 3px 6px;
      font-size: 12px;
      border-radius: 4px;
      display: inline-block;
      letter-spacing: 1px;
      user-select: none;
      pointer-events: none;
    }
  }
`;
export const StyledBg = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      320deg,
      rgb(249 78 26 / 88%),
      rgb(249 79 25 / 88%)
    );
  }
  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;
export const BoxSectionIconwrap = styled.div`
  position: relative;
`;
export const TextWrap = styled.p`
  position: relative;
`;
export const BoxSectionTextWrap = styled.div`
  position: relative;
  h4 {
    margin: 10px 0 20px;
    font-size: 22px;
    font-weight: bold;
  }
  h6 {
    color: #fff;
    font-weight: bold;
  }
  p {
    font-size: 16px;
    color: rgb(255 255 255);
    margin: 0;
    min-height: 60px;
  }
  a {
    font-size: 17px;
    color: #fff;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    text-decoration: none;
    margin-top: 30px;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: #fff;
    }
  }
  a:hover{
    font-size: 16px;
    color: #fff;
  }
`;
