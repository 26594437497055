import React, { useState } from 'react'
import { Container, Row, Col } from '@ui/wrapper'
import Image from '@ui/image'
import SectionTitle from '@ui/section-title'
import Heading from '@ui/heading'
import Text from '@ui/text'
import Anchor from '@ui/anchor'
import {
  BoxSectionWrapper,
  BoxSectionInner,
  BoxSectionWrap,
  BoxSectionWrapInner,
  BoxSectionIconwrap,
  BoxSectionTextWrap,
  StyledBg,
  TextWrap
} from './style'
import copyIcon from '@data/images/locations/copy-icon.png'

const BoxSection = ({ data }) => {
  const [copied, setCopied] = useState(null)

  const handleCopyAddress = el => {
    navigator.clipboard.writeText(
      `${el.texts
        ?.map(it => it.content)
        ?.slice(0, 2)
        ?.join(', ')
        ?.replace(/<br\s*\/?>/gi, '')
      }`
    )
    setTimeout(() => {
      setCopied(el.id)
    }, 200)
  }
  return (
    <BoxSectionWrapper id={data.section}>
      <BoxSectionInner>
        <Container>
          <Row>
            <Col>
              <SectionTitle
                subtitle={data.section_title?.subtitle}
                title={data.section_title?.title}
                description={data.section_title?.description}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <BoxSectionWrap>
                {data &&
                  data.items?.map((el, index) => (
                    <BoxSectionWrapInner key={index}>
                      <StyledBg>
                        {el.images && el.images[1] && (
                          <Image
                            src={el?.images[1]?.src}
                            alt={el?.images[1]?.alt || 'Location'}
                          />
                        )}
                      </StyledBg>
                      <button
                        type='button'
                        onClick={() => handleCopyAddress(el)}
                        className='copyButton'
                        title={'Click to Copy ' + el.title + ' Address'}
                      >
                        <Image
                          src={copyIcon}
                          alt='Copy Address'
                          width={25}
                          height={25}
                        />
                        {copied === el?.id && <span>Copied!</span>}
                      </button>
                      <BoxSectionIconwrap>
                        {el.images && el.images[0] && (
                          <Image
                            src={el?.images[0]?.src}
                            alt={el?.images[0]?.alt || 'Icon'}
                          />
                        )}
                      </BoxSectionIconwrap>
                      <BoxSectionTextWrap>
                        <Heading as='h4' className='text-white'>
                          {el.title}
                        </Heading>
                        {el.texts && (
                          <Heading as='h6'>{el.texts[0].content}</Heading>
                        )}
                        {el.texts && (
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: el.texts[1].content
                            }}
                          ></Text>
                        )}
                        {el.texts && (
                          <TextWrap
                            dangerouslySetInnerHTML={{
                              __html: el.texts[2].content
                            }}
                          />
                        )}
                        <Anchor key={index} path={el.path} target='_blank'>
                          Map
                        </Anchor>
                      </BoxSectionTextWrap>
                    </BoxSectionWrapInner>
                  ))}
              </BoxSectionWrap>
            </Col>
          </Row>
        </Container>
      </BoxSectionInner>
    </BoxSectionWrapper>
  )
}

export default BoxSection
